import {
  HiOutlineMailOpen,
  HiOutlineReceiptTax,
  HiOutlineUserGroup,
} from "react-icons/hi";
import { GiStripedSun } from "react-icons/gi";
import { FaWpforms } from "react-icons/fa";
import { VscUnverified, VscReferences } from "react-icons/vsc";
import { FiGlobe, FiHardDrive, FiShare } from "react-icons/fi";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import {
  BiCategory,
  BiAddToQueue,
  BiDonateBlood,
  BiErrorAlt,
  BiTransferAlt,
  BiUserCircle,
} from "react-icons/bi";
import {
  AiOutlineForm,
  AiOutlineIssuesClose,
  AiOutlineTransaction,
} from "react-icons/ai";
import {
  RiHandHeartLine,
  RiCurrencyLine,
  RiSecurePaymentLine,
  RiRefund2Fill,
  RiAdminLine,
} from "react-icons/ri";
import {
  BsListCheck,
  BsBank2,
  BsFileEarmarkMedical,
  BsImages,
  BsFileEarmarkCode,
  BsChatRightText,
} from "react-icons/bs";
import {
  MdOutlineManageSearch,
  MdOutlineDelete,
  MdReportGmailerrorred,
  MdOutlineSchool,
  MdOutlineLocalHospital,
  MdOutlineMessage,
  MdOutlineManageAccounts,
  MdCastForEducation,
  MdOutlineDeleteSweep,
  MdOutlineBugReport,
  MdOutlineVideoLibrary,
  MdOutlineLiveHelp,
  MdOutlineCorporateFare,
  MdOutlineQuestionAnswer,
  MdPayment,
} from "react-icons/md";
import {
  AiOutlineDashboard,
  AiOutlineSetting,
  AiOutlineStar,
  AiOutlineFileDone,
  AiOutlineDollar,
} from "react-icons/ai";
import { SiWorldhealthorganization } from "react-icons/si";
import { ReactNode } from "react";
import { RoutePermittedRole } from "../shared/constants/AppConst";
import { FiUserCheck, FiUserX } from "react-icons/fi";
import { Dvr, ReceiptOutlined } from "@mui/icons-material";
import {
  IoLanguageOutline,
  IoChatbubbleEllipsesOutline,
} from "react-icons/io5";

export interface RouterConfigData {
  id: string;
  title: string;
  messageId: string;
  icon?: string | ReactNode;
  type: "item" | "group" | "collapse" | "divider";
  children?: RouterConfigData[];
  permittedRole?: RoutePermittedRole;
  color?: string;
  url?: string;
  exact?: boolean;
  count?: number;
  isSuperAdmin?: boolean;
}

const routesConfig: RouterConfigData[] = [
  {
    id: "dashboard",
    title: "Dashboard",
    messageId: "sidebar.app.dashboard.dashboard",
    type: "item",
    icon: <AiOutlineDashboard />,
    url: "/dashboard",
  },
  {
    id: "admins",
    title: "Admins",
    messageId: "sidebar.app.dashboard.admins",
    type: "item",
    icon: <RiAdminLine />,
    url: "/admin-lists",
    isSuperAdmin: true,
  },
  {
    id: "users",
    title: "User",
    messageId: "sidebar.app.dashboard.users",
    type: "collapse",
    icon: <HiOutlineUserGroup />,
    children: [
      {
        id: "activeusers",
        title: "Active",
        messageId: "sidebar.app.dashboard.activeusers",
        type: "item",
        icon: <FiUserCheck />,
        url: "/user",
      },
      {
        id: "deletedusers",
        title: "Deactivated",
        messageId: "sidebar.app.dashboard.deletedusers",
        type: "item",
        icon: <FiUserX />,
        url: "/deleted-users",
      },
      {
        id: "marketinguserslist",
        title: "Marketing Users List",
        messageId: "sidebar.app.dashboard.marketinguserslist",
        type: "item",
        icon: <FiUserCheck />,
        url: "/marketing-users-list",
      },
      {
        id: "userform",
        title: "User Form",
        messageId: "sidebar.app.dashboard.userform",
        type: "item",
        icon: <BiUserCircle />,
        url: "/user-form",
      },
    ],
  },
  {
    id: "ngo",
    title: "NGO",
    messageId: "sidebar.app.dashboard.ngo",
    type: "collapse",
    icon: <RiHandHeartLine />,
    children: [
      {
        id: "activengo",
        title: "Active",
        messageId: "sidebar.app.dashboard.ngoList",
        type: "item",
        icon: <BsListCheck />,
        url: "/ngo",
      },
      {
        id: "deactivatedngo",
        title: "Deactivated",
        messageId: "sidebar.app.dashboard.deactivatedngo",
        type: "item",
        icon: <MdOutlineDeleteSweep />,
        url: "/deleted-ngos",
      },
      {
        id: "ngodonation",
        title: "NGO Donation",
        messageId: "sidebar.app.dashboard.ngodonation",
        type: "item",
        icon: <BiDonateBlood />,
        url: "/ngo-donation-list",
      },
      {
        id: "ngoform",
        title: "NGO Form",
        messageId: "sidebar.app.dashboard.ngoform",
        type: "item",
        icon: <BiUserCircle />,
        url: "/ngo-form",
      },
      {
        id: "nteecategory",
        title: "NTEE Category",
        messageId: "sidebar.app.dashboard.nteecategory",
        type: "item",
        icon: <BsListCheck />,
        url: "/ntee-category",
      },
      {
        id: "exemptiontype",
        title: "Exemption Type",
        messageId: "sidebar.app.dashboard.exemptiontype",
        type: "item",
        icon: <BsListCheck />,
        url: "/exemption-type",
      },
      {
        id: "organizationschool",
        title: "Organization School",
        messageId: "sidebar.app.dashboard.organizationschool",
        type: "item",
        icon: <BsListCheck />,
        url: "/organization-school",
      },
    ],
  },
  {
    id: "chatbot",
    title: "Chatbot",
    messageId: "sidebar.app.dashboard.chatbot",
    type: "collapse",
    icon: <IoChatbubbleEllipsesOutline />,
    children: [
      {
        id: "allchatbot",
        title: "All chat",
        messageId: "sidebar.app.dashboard.allchatbot",
        type: "item",
        icon: <BsChatRightText />,
        url: "/allchat",
      },
    ],
  },
  {
    id: "refer_ngo",
    title: "Refer Ngo",
    messageId: "sidebar.app.dashboard.refer_ngo",
    type: "item",
    icon: <VscReferences />,
    url: "/refer-ngo",
    isSuperAdmin: true,
  },
  {
    id: "funds",
    title: "Funds",
    messageId: "sidebar.app.dashboard.funds",
    type: "item",
    icon: <RiRefund2Fill />,
    url: "/funds",
  },
  {
    id: "helprequests",
    title: "Help Requests",
    messageId: "sidebar.app.dashboard.helprequests",
    type: "item",
    icon: <MdOutlineLiveHelp />,
    url: "/help-requests",
  },
  {
    id: "requests",
    title: "Requests",
    messageId: "sidebar.app.dashboard.managerequests",
    type: "item",
    icon: <BiAddToQueue />,
    url: "/manage-requests",
  },
  {
    id: "drives",
    title: "Drives",
    messageId: "sidebar.app.dashboard.drives",
    type: "collapse",
    icon: <FiHardDrive />,
    children: [
      {
        id: "driveslist",
        title: "Drives List",
        messageId: "sidebar.app.dashboard.driveslist",
        type: "item",
        icon: <BsListCheck />,
        url: "/drive-lists",
      },
    ],
  },
  {
    id: "management",
    title: "Management",
    messageId: "sidebar.app.dashboard.manage",
    type: "collapse",
    icon: <MdOutlineManageAccounts />,
    children: [
      {
        id: "generalDynamicForm",
        title: "General Forms",
        messageId: "sidebar.app.dashboard.generalDynamicForm",
        type: "item",
        icon: <AiOutlineForm />,
        url: "/general-dynamic-form",
      },
      {
        id: "defaultOtp",
        title: "Default Otp",
        messageId: "sidebar.app.dashboard.defaultOtp",
        type: "item",
        icon: <FiGlobe />,
        url: "/default-otp",
      },
      {
        id: "regions",
        title: "Regions",
        messageId: "sidebar.app.dashboard.regions",
        type: "item",
        icon: <FiGlobe />,
        url: "/regions",
      },
      {
        id: "countrycurrencymanage",
        title: "Country Currency Manage",
        messageId: "sidebar.app.dashboard.countrycurrencymanage",
        type: "item",
        icon: <RiCurrencyLine />,
        url: "/manage-country-currency",
      },
      // {
      //   id: "planslist",
      //   title: "Plans",
      //   messageId: "sidebar.app.dashboard.plans",
      //   type: "item",
      //   icon: <BsCardChecklist />,
      //   url: "/planslist",
      // },
      {
        id: "banklist",
        title: "Banks",
        messageId: "sidebar.app.dashboard.managebank",
        type: "item",
        icon: <BsBank2 />,
        url: "/bank-list",
      },
      {
        id: "banktypelist",
        title: "Bank Type",
        messageId: "sidebar.app.dashboard.banktype",
        type: "item",
        icon: <BsBank2 />,
        url: "/bank-type",
      },
      {
        id: "banklists",
        title: "Users Bank Lists",
        messageId: "sidebar.app.dashboard.banklists",
        type: "item",
        icon: <Dvr sx={{ fontSize: 20 }} />,
        url: "/users-bank-list",
      },
      {
        id: "emotionalmessages",
        title: "Emotional Messages",
        messageId: "sidebar.app.dashboard.emotionalmessage",
        type: "item",
        icon: <MdOutlineMessage />,
        url: "/emotional-messages",
      },
      {
        id: "emailtemplates",
        title: "Templates",
        messageId: "sidebar.app.dashboard.emailtemplates",
        type: "item",
        icon: <HiOutlineMailOpen />,
        url: "/emailtemplates",
      },
      {
        id: "delete-account",
        title: "Delete Account",
        messageId: "sidebar.app.dashboard.deleteAccount",
        type: "item",
        icon: <MdOutlineDelete />,
        url: "/delete-account",
      },
      {
        id: "schoollists",
        title: "School",
        messageId: "sidebar.app.dashboard.schoollist",
        type: "item",
        icon: <MdOutlineSchool />,
        url: "/school-list",
      },
      {
        id: "hospitalists",
        title: "Hospital",
        messageId: "sidebar.app.dashboard.hospitallist",
        type: "item",
        icon: <MdOutlineLocalHospital />,
        url: "/hospital-list",
      },
      {
        id: "csvfiles",
        title: "CSV Files",
        messageId: "sidebar.app.dashboard.csvfiles",
        type: "item",
        icon: <InsertDriveFileOutlinedIcon sx={{ fontSize: 20 }} />,
        url: "/csvfiles",
      },
      {
        id: "useraddedhospitalschool",
        title: "Unverified Hospitals/Schools",
        messageId: "sidebar.app.dashboard.useraddedhospitalschool",
        type: "item",
        icon: <VscUnverified />,
        url: "/unverified-hospital-school",
      },
      {
        id: "courselists",
        title: "Courses",
        messageId: "sidebar.app.dashboard.courselists",
        type: "item",
        icon: <MdCastForEducation />,
        url: "/course-list",
      },
      {
        id: "diseaselists",
        title: "Diseases",
        messageId: "sidebar.app.dashboard.diseaselists",
        type: "item",
        icon: <BsFileEarmarkMedical />,
        url: "/disease-list",
      },
      {
        id: "imageslists",
        title: "Images",
        messageId: "sidebar.app.dashboard.imageslists",
        type: "item",
        icon: <BsImages />,
        url: "/images-lists",
      },
      {
        id: "footerstrips",
        title: "Images",
        messageId: "sidebar.app.dashboard.footerstrips",
        type: "item",
        icon: <GiStripedSun />,
        url: "/footer-strip",
      },
      {
        id: "languages",
        title: "Languages",
        messageId: "sidebar.app.dashboard.languages",
        type: "item",
        icon: <IoLanguageOutline />,
        url: "/languages",
      },
      {
        id: "sharemessage",
        title: "Share Message",
        messageId: "sidebar.app.dashboard.sharemessage",
        type: "item",
        icon: <FiShare />,
        url: "/sharemessage",
      },
    ],
  },
  {
    id: "race-religion",
    title: "Race/Religion",
    messageId: "sidebar.app.dashboard.raceReligion",
    type: "collapse",
    icon: <SiWorldhealthorganization />,
    children: [
      {
        id: "racelist",
        title: "Race List",
        messageId: "sidebar.app.dashboard.racelist",
        type: "item",
        icon: <BsListCheck />,
        url: "/race-list",
      },
      {
        id: "religionlist",
        title: "Religion List",
        messageId: "sidebar.app.dashboard.religionlist",
        type: "item",
        icon: <BsListCheck />,
        url: "/religion-list",
      },
    ],
  },
  {
    id: "transactionlist",
    title: "Transaction List",
    messageId: "sidebar.app.dashboard.transaction",
    type: "collapse",
    icon: <BiTransferAlt />,
    children: [
      {
        id: "admindonation",
        title: "Admin Donations",
        messageId: "sidebar.app.dashboard.admindonation",
        type: "item",
        icon: <AiOutlineFileDone />,
        url: "/transactions/admin-donation-list",
      },
      {
        id: "transferredfund",
        title: "Transferred Fund",
        messageId: "sidebar.app.dashboard.transferredfund",
        type: "item",
        icon: <BsListCheck />,
        url: "/transfer-funds-lists",
        isSuperAdmin: true,
      },
      {
        id: "recurringdonation",
        title: "Recurring Donation",
        messageId: "sidebar.app.dashboard.recurringdonation",
        type: "item",
        icon: <BsListCheck />,
        url: "/recurring-donation-lists",
        isSuperAdmin: true,
      },
      {
        id: "featuredlist",
        title: "featured",
        messageId: "sidebar.app.dashboard.featuredlist",
        type: "item",
        icon: <AiOutlineStar />,
        url: "/transactions/featured-list",
      },
      {
        id: "saayamtransactions",
        title: "Cancelled Transactions",
        messageId: "sidebar.app.dashboard.saayamtransactions",
        type: "item",
        icon: <ReceiptOutlined sx={{ fontSize: 20 }} />,
        url: "/transactions/saayam-transactions",
      },
      {
        id: "donationlist",
        title: "Donations",
        messageId: "sidebar.app.dashboard.donationlist",
        type: "item",
        icon: <AiOutlineDollar />,
        url: "/transactions/donation-list",
      },
      {
        id: "taxbenefits",
        title: "Tax Benefits",
        messageId: "sidebar.app.dashboard.taxbenefits",
        type: "item",
        icon: <HiOutlineReceiptTax />,
        url: "/transactions/taxbenefits",
      },
      {
        id: "manual transaction",
        title: "Manual Transactions",
        messageId: "sidebar.app.dashboard.manualtransaction",
        type: "item",
        icon: <AiOutlineTransaction />,
        url: "/manual_transaction",
      },
      {
        id: "paymentgateway",
        title: "Payment Gateway",
        messageId: "sidebar.app.dashboard.paymentgateway",
        type: "item",
        icon: <RiSecurePaymentLine />,
        url: "/transactions/payment-gateway-list",
      },
      {
        id: "paymentmode",
        title: "Mode of Payment",
        messageId: "sidebar.app.dashboard.paymentmode",
        type: "item",
        icon: <MdPayment />,
        url: "/transactions/payment-mode",
      },
      {
        id: "tiplist",
        title: "Tip Received",
        messageId: "sidebar.app.dashboard.tiplist",
        type: "item",
        icon: <BsListCheck />,
        url: "/transactions/tip-list",
      },
    ],
  },
  {
    id: "cms",
    title: "Cms",
    messageId: "sidebar.app.dashboard.cms",
    type: "collapse",
    icon: <MdOutlineManageSearch />,
    children: [
      {
        id: "Home CMS",
        title: "Home CMS",
        messageId: "sidebar.app.dashboard.homeCmsList",
        type: "item",
        icon: <BsListCheck />,
        url: "/home-cms",
      },
      {
        id: "ngodonation",
        title: "Other CMS",
        messageId: "sidebar.app.dashboard.othercms",
        type: "item",
        icon: <BsListCheck />,
        url: "/cms",
      },
    ],
  },
  {
    id: "settings",
    title: "Settings",
    messageId: "sidebar.app.dashboard.settings",
    type: "collapse",
    icon: <AiOutlineSetting />,
    children: [
      {
        id: "othersettings",
        title: "Settings",
        messageId: "sidebar.app.dashboard.settings",
        type: "item",
        icon: <BsListCheck />,
        url: "/setting",
      },
      {
        id: "commonsettings",
        title: "Common Settings",
        messageId: "sidebar.app.dashboard.commonsettings",
        type: "item",
        icon: <BsListCheck />,
        url: "/common-settings",
      },
    ],
  },
  {
    id: "corporate",
    title: "Corporate",
    messageId: "sidebar.app.dashboard.corporate",
    type: "collapse",
    icon: <MdOutlineCorporateFare />,
    children: [
      {
        id: "corporatetypes",
        title: "Corporate Types",
        messageId: "sidebar.app.dashboard.corporatetypes",
        type: "item",
        icon: <BsListCheck />,
        url: "/corporate-types",
      },
      {
        id: "corporatecountryforms",
        title: "Corporate Country Forms",
        messageId: "sidebar.app.dashboard.corporatecountryforms",
        type: "item",
        icon: <BsListCheck />,
        url: "/corporate-country-form",
      },
      {
        id: "jobtitle",
        title: "Job Title",
        messageId: "sidebar.app.dashboard.jobtitle",
        type: "item",
        icon: <BsListCheck />,
        url: "/job-title",
      },
      {
        id: "corporatelist",
        title: "Corporate List",
        messageId: "sidebar.app.dashboard.corporatelists",
        type: "item",
        icon: <BsListCheck />,
        url: "/corporate-lists",
      },
      {
        id: "deactivated",
        title: "Deactivated",
        messageId: "sidebar.app.dashboard.deactivated",
        type: "item",
        icon: <FiUserX />,
        url: "/deleted-corporate",
      },
    ],
  },
  {
    id: "corporateform",
    title: "Corporate Form",
    messageId: "sidebar.app.dashboard.corporateform",
    type: "collapse",
    icon: <MdOutlineCorporateFare />,
    children: [
      {
        id: "requestacceptform",
        title: "Request Accept",
        messageId: "sidebar.app.dashboard.requestacceptform",
        type: "item",
        icon: <BsListCheck />,
        url: "/corporate-request-accept-form",
      },
      {
        id: "requestrejectform",
        title: "Request Reject",
        messageId: "sidebar.app.dashboard.requestrejectform",
        type: "item",
        icon: <BsListCheck />,
        url: "/corporate-request-reject-form",
      },
    ],
  },
  {
    id: "volunteersform",
    title: "Volunteers Form",
    messageId: "sidebar.app.dashboard.volunteersform",
    type: "collapse",
    icon: <FaWpforms />,
    children: [
      {
        id: "requestacceptform",
        title: "Request Accept",
        messageId: "sidebar.app.dashboard.requestacceptform",
        type: "item",
        icon: <BsListCheck />,
        url: "/request-accept-form",
      },
      {
        id: "requestrejectform",
        title: "Request Reject",
        messageId: "sidebar.app.dashboard.requestrejectform",
        type: "item",
        icon: <BsListCheck />,
        url: "/request-reject-form",
      },
    ],
  },
  {
    id: "reports",
    title: "Report Forms",
    messageId: "sidebar.app.dashboard.reportForm",
    type: "collapse",
    icon: <MdReportGmailerrorred />,
    children: [
      {
        id: "helpRequestForm",
        title: "Help Requests Form",
        messageId: "sidebar.app.dashboard.helpRequestForm",
        type: "item",
        icon: <BsListCheck />,
        url: "/help-requests-report-form",
      },
      {
        id: "reportRequestForm",
        title: "Requests Form",
        messageId: "sidebar.app.dashboard.reportRequestForm",
        type: "item",
        icon: <BsListCheck />,
        url: "/requests-report-form",
      },
      {
        id: "NGOReportForm",
        title: "NGOs Form",
        messageId: "sidebar.app.dashboard.reportNGOForm",
        type: "item",
        icon: <BsListCheck />,
        url: "/ngo-report-form",
      },
      {
        id: "fundreportform",
        title: "Funds Form",
        messageId: "sidebar.app.dashboard.fundreportform",
        type: "item",
        icon: <BsListCheck />,
        url: "/fund-report-form",
      },
      {
        id: "drivereportform",
        title: "Drive Form",
        messageId: "sidebar.app.dashboard.drivereportform",
        type: "item",
        icon: <BsListCheck />,
        url: "/report-drive",
      },
    ],
  },
  {
    id: "causes",
    title: "Causes",
    messageId: "sidebar.app.dashboard.category",
    type: "item",
    icon: <BiCategory />,
    url: "/categories",
  },
  {
    id: "causesoptions",
    title: "Causes Options",
    messageId: "sidebar.app.dashboard.causesOptions",
    type: "collapse",
    icon: <BiCategory />,
    children: [
      {
        id: "drivetypes",
        title: "Drive Types",
        messageId: "sidebar.app.dashboard.drivetypes",
        type: "item",
        icon: <BsListCheck />,
        url: "/drive-types",
      },
      {
        id: "reqireditems",
        title: "Drive Type Required Items",
        messageId: "sidebar.app.dashboard.reqireditems",
        type: "item",
        icon: <BsListCheck />,
        url: "/drive-types-required-items",
      },
      {
        id: "donationpurpose",
        title: "Donation Purpose",
        messageId: "sidebar.app.dashboard.donationpurpose",
        type: "item",
        icon: <BsListCheck />,
        url: "/donation-purpose",
      },
      {
        id: "disastertypes",
        title: "Disaster Types",
        messageId: "sidebar.app.dashboard.disastertypes",
        type: "item",
        icon: <BsListCheck />,
        url: "/disaster-types",
      },
      {
        id: "humanrights",
        title: "Human Rights",
        messageId: "sidebar.app.dashboard.humanrights",
        type: "item",
        icon: <BsListCheck />,
        url: "/human-rights",
      },
      {
        id: "womenempowermentlist",
        title: "Women Empowerment List",
        messageId: "sidebar.app.dashboard.womenempowermentlist",
        type: "item",
        icon: <BsListCheck />,
        url: "/women-empowerment-list",
      },
      {
        id: "elderlytypes",
        title: "Elderly Types",
        messageId: "sidebar.app.dashboard.elderlytypes",
        type: "item",
        icon: <BsListCheck />,
        url: "/elderly-types",
      },
      {
        id: "beneficiary_relation",
        title: "Beneficiary Relation",
        messageId: "sidebar.app.dashboard.beneficiary_relation",
        type: "item",
        icon: <BsListCheck />,
        url: "/beneficiary-relation",
      },
      {
        id: "beneficiary_status",
        title: "Beneficiary Status",
        messageId: "sidebar.app.dashboard.beneficiary_status",
        type: "item",
        icon: <BsListCheck />,
        url: "/beneficiary-status",
      },
      {
        id: "institution_type",
        title: "Institution Types",
        messageId: "sidebar.app.dashboard.institution_type",
        type: "item",
        icon: <BsListCheck />,
        url: "/institution-type",
      },
      {
        id: "affiliation_type",
        title: "Affiliation Types",
        messageId: "sidebar.app.dashboard.affiliation_type",
        type: "item",
        icon: <BsListCheck />,
        url: "/affiliation-type",
      },
      {
        id: "administrator_role",
        title: "Administrator Role",
        messageId: "sidebar.app.dashboard.administrator_role",
        type: "item",
        icon: <BsListCheck />,
        url: "/administrator-role",
      },
      {
        id: "representative_title",
        title: "Representative Title",
        messageId: "sidebar.app.dashboard.representative_title",
        type: "item",
        icon: <BsListCheck />,
        url: "/representative-title",
      },
      {
        id: "registration_types",
        title: "Registration Types",
        messageId: "sidebar.app.dashboard.registration_types",
        type: "item",
        icon: <BsListCheck />,
        url: "/registration-types",
      },
    ],
  },
  {
    id: "sendNotification",
    title: "Send Notification",
    messageId: "sidebar.app.dashboard.sendNotification",
    type: "item",
    icon: <AiOutlineIssuesClose />,
    url: "/sendnotification",
  },
  {
    id: "userroles",
    title: "User Roles",
    messageId: "sidebar.app.dashboard.userroles",
    type: "item",
    icon: <BiUserCircle />,
    url: "/user-roles",
  },
  {
    id: "successStories",
    title: "Success Stories",
    messageId: "sidebar.app.dashboard.successStories",
    type: "item",
    icon: <BiUserCircle />,
    url: "/success-stories",
  },
  {
    id: "testimonials",
    title: "Testimonials",
    messageId: "sidebar.app.dashboard.testimonials",
    type: "item",
    icon: <MdOutlineVideoLibrary />,
    url: "/testimonials",
  },
  {
    id: "vibeslist",
    title: "Vibes List",
    messageId: "sidebar.app.dashboard.vibeslist",
    type: "item",
    icon: <MdOutlineVideoLibrary />,
    url: "/vibes",
  },
  {
    id: "bugreports",
    title: "Bug Reports",
    messageId: "sidebar.app.dashboard.bugreports",
    type: "item",
    icon: <MdOutlineBugReport />,
    url: "/bugreports",
  },
  {
    id: "faq",
    title: "FAQ",
    messageId: "sidebar.pages.extraPages.faq",
    type: "item",
    icon: <MdOutlineQuestionAnswer />,
    url: "/faq",
  },
  {
    id: "errorlog",
    title: "Error Logs",
    messageId: "sidebar.app.dashboard.errorlog",
    type: "item",
    icon: <BiErrorAlt />,
    url: "/errorlog",
  },
  {
    id: "adminlogs",
    title: "Admin Logs",
    messageId: "sidebar.app.dashboard.adminlogs",
    type: "item",
    icon: <BsFileEarmarkCode />,
    url: "/adminlogs",
    isSuperAdmin: true,
  },
];
export default routesConfig;
